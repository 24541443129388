import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Collapse, Grid, Icon, IconButton, Link, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import SectionContainer from '../../global/SectionContainer';
import { LIVE_PROCESSING } from '../../../utils/helper/nanostream-cloud';
import { Cached, CloudSync, CloudSyncOutlined, CopyAll, DownloadingOutlined, HideImage, HideImageOutlined, HighlightOff, InfoOutlined, InfoRounded, KeyboardArrowDown, KeyboardArrowUp, Loop, PermMedia, PermMediaOutlined, SwitchAccessShortcutAdd, TaskAltOutlined } from '@mui/icons-material';
import CopyButton from '../../global/CopyButton';
import Loading from '../../global/Loading';
import { TabContext, TabList } from '@mui/lab';

const TabContent = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <Box
            hidden={value !== index}
            {...other}
        >
            {value === index && <Fragment>{children}</Fragment>}
        </Box>
    );
}


const ImageNotAvailable = (props) => {
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const { title, underline, notAvailable, children, onRefresh } = props;

    useEffect(() => {
        if (loading) {
            setTimeout(() => {
                setLoading(false)
            }, 600)
        }

    }, [loading])

    return (
        <SectionContainer
            noMargin title={title} underline={underline} clicked={onRefresh}
            interact button={`Refresh ${title}`} icon={<CloudSyncOutlined />}
        >
            {
                notAvailable
                    ?
                    <Stack
                        direction={"column"} alignItems={"center"} justifyContent={"center"}
                        sx={{ bgcolor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[800], my: 1, py: 1, borderRadius: theme.spacing(1) }}
                    >
                        <Fragment>

                            {
                                loading
                                    ? <Loading />
                                    :
                                    <Icon size="large">
                                        <HideImageOutlined color="disabled" />
                                    </Icon>
                            }
                            <Typography variant="h6" sx={{ color: theme.palette.text.disabled }}>
                                {loading ? `Fetching ${title}` : `${title} not available`}
                            </Typography>
                        </Fragment>
                    </Stack>
                    : children

            }
        </SectionContainer >
    )
}

const TableCellTitle = (props) => {
    const { title, info } = props;
    return (
        <TableCell textAlign="center">
            <Stack direction="row" spacing={1} alignItems="center" useFlexGap>
                <Typography variant="button" color="textSecondary" sx={{ lineHeight: 1 }}>
                    {title}
                </Typography>
                {
                    info &&
                    <Tooltip arrow title={info}>
                        <InfoOutlined fontSize="small" color="disabled" ƒ />
                    </Tooltip>
                }
            </Stack>
        </TableCell>
    )
}

const Links = (props) => {
    const { opcode, data } = props;
    const [open, setOpen] = useState(false);

    const openLinks = () => { setOpen(!open) }

    return (
        <Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton size="small" onClick={openLinks}>
                        {open ? <KeyboardArrowUp fontSize="small" /> : <KeyboardArrowDown fontSize="small" />}
                    </IconButton>
                </TableCell>
                <TableCellTitle title={opcode.title} />
                <TableCellTitle title={data.length} />
            </TableRow>
            <TableRow>
                <TableCell sx={{ py: open ? 2 : 0 }} colSpan={3}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <SectionContainer noMargin contrast>
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCellTitle title="Stream Name" />
                                            <TableCellTitle title="Type" />
                                            <TableCellTitle title="URL" />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            data.map((d, i) => (
                                                <TableRow key={d.streamId}>
                                                    <TableCell>
                                                        <Typography variant="body2">
                                                            {d.streamname}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCellTitle title={d.type} />
                                                    <TableCell>
                                                        <Stack direction="row" spacing={1} alignItems={"center"} justifyContent={"space-between"}>
                                                            <Link href={d.url} underline="always" target="_blank">
                                                                {d.url}
                                                            </Link>
                                                            <CopyButton iconButton copy={d.url} />
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </SectionContainer>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    )
}

export default function LiveProcessingOverview(props) {
    const theme = useTheme();
    const navigate = useNavigate();
    const { stream, handleClicked, previewOnly } = props;

    const findFirst = (opcodeId, key) => {
        const streamWithKey = stream.playout.rtmp?.find(s => s.processing.some(opcode => opcode.id === opcodeId));

        if (streamWithKey) {
            const resource = stream.playout[key]?.find(res => res.streamId === streamWithKey.id);
            return resource ? resource.url : "";
        }
        return "";
    };

    const [tabIndex, setTabIndex] = useState(0);
    const [thumbnailUrl, setThumbnailUrl] = useState(findFirst(LIVE_PROCESSING.thumbs.id, 'thumbnails'));
    const [motionclipUrl, setMotionclipUrl] = useState(findFirst(LIVE_PROCESSING.motionclip.id, 'motionclip'));
    const [noThumbnail, setNoThumbnail] = useState(false);
    const [noMotionclip, setNoMotionclip] = useState(false);

    const handleTabbing = (event, newValue) => { setTabIndex(newValue); };

    const handleThumbnailError = (err) => { setNoThumbnail(true); }
    const handleMotionclipError = (err) => { setNoMotionclip(true); }

    const handleRefreshThumbnail = () => setThumbnailUrl(`${findFirst(LIVE_PROCESSING.thumbs.id, 'thumbnails')}?timestamp=${new Date().getTime()}`);
    const handleRefreshMotionclip = () => setMotionclipUrl(`${findFirst(LIVE_PROCESSING.motionclip.id, 'motionclip')}?timestamp=${new Date().getTime()}`);

    console.log(stream, motionclipUrl)
    return (
        <Grid container spacing={2}>
            {
                stream.playout?.thumbnails &&
                <Grid item xs={12} sm={6}>
                    <ImageNotAvailable
                        title={"Thumbnail"} notAvailable={noThumbnail} onRefresh={handleRefreshThumbnail}
                        underline={"The thumbnail for the ongoing stream is captured based on the set interval. Refresh the page to update the preview."}
                    >
                        <Box
                            component="img" alt="Thumbnail Preview"
                            sx={{ height: '100%', width: '100%', mt: 1, borderRadius: theme.spacing(.5) }}
                            src={thumbnailUrl} onError={handleThumbnailError}
                        />
                    </ImageNotAvailable>
                </Grid>
            }
            {
                stream.playout?.motionclip &&
                <Grid item xs={12} sm={6}>
                    <ImageNotAvailable
                        title={"Motion Clip"} notAvailable={noMotionclip} onRefresh={handleRefreshMotionclip}
                        underline={"The motionclip for the ongoing stream is captured based on the set interval. Refresh the page to update the preview."}
                    >
                        <Box
                            component="img" alt="Motionclip Preview"
                            sx={{ height: '100%', width: '100%', mt: 1, borderRadius: theme.spacing(.5) }}
                            src={motionclipUrl} onError={handleMotionclipError}
                        />
                    </ImageNotAvailable>
                </Grid>
            }
            {
                !previewOnly &&
                <Grid item xs={12} md={6}>
                    <SectionContainer noMargin
                        title="Live Processing" underline="Overview of enabled processing features and their respective settings, designed to enhance playback and content management."
                        button="Edit Options" icon={<SwitchAccessShortcutAdd />} interact
                        clicked={handleClicked}
                    >
                        <TabContext value={tabIndex}>
                            {
                                stream.playout.rtmp?.length > 1 &&
                                <TabList onChange={handleTabbing}>
                                    {
                                        stream.playout.rtmp?.map((s, i) => {
                                            let label = s.index === 0 ? "Passthrough" : `${s.index}. Transcode`;
                                            return (<Tab key={i} label={label} value={i} />)
                                        })
                                    }
                                </TabList>
                            }
                            {
                                stream.playout.rtmp?.map((s, i) => {
                                    let label = s.index === 0 ? "Passthrough" : `${s.index}. Transcode`;
                                    let streamname = s.streamname

                                    return (
                                        <TabContent key={`${streamname}-${i}`} value={tabIndex} index={i}>
                                            <SectionContainer
                                                contrast small
                                                title={`Live Processing Details`}
                                                underline={`Showing ${label} stream with name ${streamname}.`}
                                            >
                                                <TableContainer sx={{ mt: 1 }}>
                                                    <Table size="small">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell />
                                                                <TableCellTitle title="Is Active" />
                                                                <TableCellTitle title="Interval (s)" />
                                                                <TableCellTitle title="Duration (s)" />
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                Object.keys(LIVE_PROCESSING).map((o, i) => {
                                                                    let opcode = LIVE_PROCESSING[o];
                                                                    const process = s.processing?.find(p => p.id === opcode.id);
                                                                    const hasProcess = process ? true : false;
                                                                    const isEntireStream = hasProcess && (process.id === "rec" || process.id === "replay");

                                                                    return (
                                                                        <TableRow>
                                                                            <TableCellTitle info={opcode.underline} title={opcode.title} />
                                                                            <TableCell>
                                                                                <Icon>
                                                                                    {
                                                                                        hasProcess
                                                                                            ? <TaskAltOutlined color="primary" fontSize="small" />
                                                                                            : <HighlightOff color="disabled" fontSize="small" />

                                                                                    }
                                                                                </Icon>
                                                                            </TableCell>
                                                                            {
                                                                                hasProcess && process.hasOwnProperty("interval")
                                                                                    ? <TableCellTitle title={process?.interval} />
                                                                                    : <TableCell><HighlightOff color="disabled" fontSize="small" /></TableCell>
                                                                            }
                                                                            {
                                                                                hasProcess && process.hasOwnProperty("duration")
                                                                                    ? <TableCellTitle title={isEntireStream ? "Entire Stream" : process?.duration} />
                                                                                    : <TableCell><HighlightOff color="disabled" fontSize="small" /></TableCell>
                                                                            }
                                                                        </TableRow>
                                                                    )
                                                                })
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </SectionContainer>

                                        </TabContent>
                                    )
                                })
                            }
                        </TabContext>
                    </SectionContainer>
                </Grid>
            }
            <Grid item xs={12} md={previewOnly ? 12 : 6}>
                <SectionContainer noMargin title="Live Processing Outputs" underline="Overview of artifacts generated by each processing feature. Static entries link to the most recent artifact, with older artifacts organized chronologically by creation date.">
                    <TableContainer sx={{ mt: 1 }}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={2} />
                                    <TableCellTitle title="Available Links" />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    Object.keys(LIVE_PROCESSING).map((o, i) => {
                                        let opcode = LIVE_PROCESSING[o];
                                        let opcodeId = opcode.id === "thumbs" ? "thumbnails" : opcode.id
                                        const data = stream.playout[opcodeId];
                                        const hasData = data ? true : false;

                                        return hasData && <Links opcode={opcode} data={data} />;
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </SectionContainer>
            </Grid>

        </Grid>
    )
}
